import { Action } from 'redux';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { REQUEST_FAILURE, REQUEST_START, REQUEST_SUCCESS } from 'store/requests.actions';
import { AvailableLoadSearchHistory } from 'shared/models/loads/available-load-search-history.model';
import { SearchHistoryLocations } from 'shared/models/recent-searches/search-history-locations.model';

export const FETCH_SEARCH_HISTORY_LOCATIONS = `${REQUEST_START}:FETCH_SEARCH_HISTORY_LOCATIONS`;
export const FETCH_SEARCH_HISTORY_LOCATIONS_SUCCESS = `${REQUEST_SUCCESS}:FETCH_SEARCH_HISTORY_LOCATIONS_SUCCESS`;
export const FETCH_SEARCH_HISTORY_LOCATIONS_FAILURE = `${REQUEST_FAILURE}:FETCH_SEARCH_HISTORY_LOCATIONS_FAILURE`;

export const fetchSearchHistoryLocations = () => ({ type: FETCH_SEARCH_HISTORY_LOCATIONS });
export const fetchSearchHistoryLocationsSucceeded = (payload: SearchHistoryLocations) => ({ type: FETCH_SEARCH_HISTORY_LOCATIONS_SUCCESS, payload });
export const fetchSearchHistoryLocationsFailed = (error: AjaxError) => ({ type: FETCH_SEARCH_HISTORY_LOCATIONS_FAILURE, error });

export interface SelectSearchHistoryAction extends Action { entry: AvailableLoadSearchHistory; }
